.picture {
  width: 100%;
  height: 0;
  padding-top: 50%;
  position: relative;
}

.carousel-inner {
  width: 100%;
  max-height: 600px !important;
}

.bg-clear {
  background-color: #000000C0;
}

@media (min-width: 1000px) {

  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-height {
  line-height: 30px;
}